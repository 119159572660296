import Tooltip from '@material-ui/core/Tooltip';
import { css } from '@emotion/css/macro';
import React from 'react';
import { Edit } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ChipPropsColorOverrides } from '@mui/material/Chip/Chip';
import { Channel } from '../../../core/types/api';
import { Button } from '../../../core/components';
import MaterialDialog from '../../../flyout/components/MaterialDialog';
import { useToggle } from '../../../core/hooks';
import { useReportAccess } from '../../../auth/hooks';
import { useItemContext } from '../../../rocTemplates/context/ItemContext';
import SimplifiedButton from '../../../rocTemplates/simplifiedControls/SimplifiedButton';
import { CustomSaqStatus } from '../../../customSaq/enums';
import {
	DEFAULT_RFT_NA_MESSAGE,
	DEFAULT_RFT_NOT_TESTED_MESSAGE,
} from '../../../customSaq/constants';

const channelClass = css`
	display: flex;
	align-items: center;
	margin: 0.25rem 0.5rem 0.25rem 0;
`;

type NotApplicableReasonProps = {
	status: CustomSaqStatus;
	pc: Channel;
	overrideSaq: any;
	useDifferentValuesPerPaymentChannels?: boolean;
};

type channelDetailsProps = {
	message: string;
	color: OverridableStringUnion<'default' | 'warning' | 'error', ChipPropsColorOverrides>;
};

function ChannelReason({
	status,
	pc,
	overrideSaq,
	useDifferentValuesPerPaymentChannels,
}: NotApplicableReasonProps) {
	const override = React.useCallback(() => overrideSaq(pc?.id), [pc, overrideSaq]);
	const { isAQSA } = useReportAccess();
	const { isOn, toggleOn, toggleOff } = useToggle();
	const confirm = React.useCallback(() => {
		override();
		toggleOff();
	}, [toggleOff, override]);
	const label = pc?.name;

	const { message, color }: channelDetailsProps = React.useMemo(() => {
		switch (status) {
			case CustomSaqStatus.NotApplicable:
				return {
					message: pc?.saq?.notApplicableReason || DEFAULT_RFT_NA_MESSAGE,
					color: 'default',
				};
			case CustomSaqStatus.NotTested:
				return { message: DEFAULT_RFT_NOT_TESTED_MESSAGE, color: 'warning' };
			default:
				return { message: 'Unknown', color: 'error' };
		}
	}, [pc, status]);

	return (
		<>
			<Box className={channelClass}>
				<Tooltip title="RFT N/A Reason">
					<Chip label={label} color={color} />
				</Tooltip>
				<Box margin="0 0.5rem">{message}</Box>
				{useDifferentValuesPerPaymentChannels && isAQSA && (
					<SimplifiedButton startIcon={<Edit />} onClick={toggleOn} size="small" tabIndex={-1}>
						Override
					</SimplifiedButton>
				)}
			</Box>
			<MaterialDialog
				title={`Override the default response for ${label}?`}
				isOpen={isOn}
				actions={
					<>
						<Button color="primary" onClick={confirm}>
							Yes
						</Button>
						<Button color="secondary" onClick={toggleOff}>
							Cancel
						</Button>
					</>
				}
				onClose={toggleOff}
			>
				If no response is entered, the default message will be preserved
			</MaterialDialog>
		</>
	);
}

function SummarySaqStatusHint({ states = [] }: any) {
	if (
		states.length === 0 ||
		states.filter((s) => s.status === CustomSaqStatus.Applicable).length > 0
	)
		return null;

	if (states.filter((s) => s.status === CustomSaqStatus.NotTested).length > 0)
		return <h3>Not tested</h3>;

	return <h3>Not applicable</h3>;
}

function ChannelReasonList({ notApplicableReasons }: { notApplicableReasons: any[] }) {
	const { paymentChannels = [], isAQSA } = useReportAccess();
	const { lists, overrideSaqPC, useDifferentValuesPerPaymentChannels, itemProperties } =
		useItemContext();

	const reasons = React.useMemo(() => {
		const applicableChannelIds = lists.allChannelStates
			.filter((c) => c.status === CustomSaqStatus.Applicable)
			.map((s) => s.cid);

		return paymentChannels
			.filter(
				({ id = '' }) =>
					applicableChannelIds.indexOf(id) < 0 &&
					(!notApplicableReasons ||
						notApplicableReasons.map(({ paymentChannelId }) => paymentChannelId).indexOf(id) < 0),
			)
			.map((pc, idx) => (
				<ChannelReason
					status={
						lists.allChannelStates.filter(
							(c) => c.status !== CustomSaqStatus.Applicable && c.cid === pc.id,
						)[0].status
					}
					pc={pc}
					overrideSaq={overrideSaqPC}
					key={idx}
					useDifferentValuesPerPaymentChannels={useDifferentValuesPerPaymentChannels}
				/>
			));
	}, [
		lists.allChannelStates,
		paymentChannels,
		notApplicableReasons,
		overrideSaqPC,
		useDifferentValuesPerPaymentChannels,
	]);

	const overrideSummary = React.useCallback(() => overrideSaqPC(undefined), [overrideSaqPC]);

	if (
		paymentChannels.length === 0 ||
		reasons.length === 0 ||
		(itemProperties.isSummary && lists.applicableChannelIds.length > 0) ||
		lists.allChannelStates.filter((s) => !s.cid && s.status === CustomSaqStatus.Applicable).length >
			0
	)
		return null;

	return (
		<>
			{itemProperties.isSummary && (
				<Box>
					<SummarySaqStatusHint states={lists.allChannelStates} />
					{isAQSA && (
						<SimplifiedButton startIcon={<Edit />} onClick={overrideSummary}>
							Override
						</SimplifiedButton>
					)}
				</Box>
			)}
			<Box padding="4px 0">{reasons}</Box>
		</>
	);
}

export default React.memo(ChannelReasonList);
