import { css } from '@emotion/css/macro';
import Fab from '@material-ui/core/Fab';
import { ArrowBack, Home } from '@mui/icons-material';
import { useNavigation } from '../hooks';
import Gates from './Gates';

const innerClass = css`
	margin: auto;
	text-align: center;
`;

const messageClass = css`
	margin-top: 2rem;
	button {
		margin: 0 0.5rem;
	}
`;

export default function PageNotFound() {
	const { goBack, goToPortal } = useNavigation();

	return (
		<Gates className={innerClass}>
			<h2>Page not found</h2>
			<div className={messageClass}>
				<Fab color="secondary" onClick={goBack}>
					<ArrowBack />
				</Fab>
				<Fab color="primary" onClick={goToPortal}>
					<Home />
				</Fab>
			</div>
		</Gates>
	);
}
