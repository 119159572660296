import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Global } from '@emotion/react/macro';
import { css } from '@emotion/css/macro';
import Auth from './features/Auth';
import ForgotPassword from './features/ForgotPassword';
import ResetPassword from './features/ResetPassword';
import SetPassword from './features/SetPassword';
import Portal from './features/Portal';
import UserActivation from './features/UserActivation';
import LockoutEnd from './features/LockoutEnd';
import UserRegistration from './features/UserRegistration';
import ClientUserRegistration from './features/ClientUserRegistration';
import Public from './features/Public';
import { PageNotFound } from './modules/core/components';
import PopUp from './modules/popup/components/PopUp';
import MainLoader from './modules/loading/MainLoader';
import globalStyle from './modules/ui/Core/stylesheets/global';
import CookieNotification from './modules/legal/CookieNotification';
import { ROUTER_PATHS } from './modules/core/constants/paths';
import { GlobalFooter } from './modules/core/components/GlobalFooter';
import { TempLogin } from './modules/request/TempLogin';
import Unsubscribe from './features/Unsubscribe';
import Impersonate from './features/Impersonate';

const mainClass = css`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

export default function App() {
	return (
		<>
			<Global styles={globalStyle} />
			<MainLoader />
			<PopUp />
			<CookieNotification />
			<div className={mainClass}>
				<React.Suspense fallback={null}>
					<Switch>
						<Route exact path="/">
							<Redirect to="/login" />
						</Route>
						<Route exact path="/login" component={Auth} />
						<Route exact path="/user/register" component={UserRegistration} />
						<Route exact path="/client/register" component={ClientUserRegistration} />
						<Route exact path="/user/activate" component={UserActivation} />
						<Route exact path="/user/lockout" component={LockoutEnd} />
						<Route exact path="/password/forgot" component={ForgotPassword} />
						<Route exact path="/password/reset" component={ResetPassword} />
						<Route exact path="/password/set" component={SetPassword} />
						<Route exact path="/temp-login" component={TempLogin} />
						<Route exact path={ROUTER_PATHS.POLICIES} component={Public} />
						<Route exact path="/unsubscribe" component={Unsubscribe} />
						<Route exact path="/impersonate" component={Impersonate} />
						<Route path="/respond" component={Portal} />
						<Route path="/project" component={Portal} />
						<Route path="/portal" component={Portal} />
						<Route component={PageNotFound} />
					</Switch>
				</React.Suspense>
				<GlobalFooter />
			</div>
		</>
	);
}
