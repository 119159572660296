import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { parse } from 'query-string';
import fetch, { FetchAPIProps } from './actions/fetchAPI';
import { checkAuth } from '../auth/AuthReducers';
import { getAvatarUrl, goBack } from './helpers';
import { useConfirmation } from '../confirmation/context';

export function useCheckboxHandle(setState: React.Dispatch<React.SetStateAction<boolean>>) {
	return React.useCallback((e) => setState(e.target.checked), [setState]);
}

export function useToggle(initial = false) {
	const [isOn, setOn] = React.useState(initial);

	const handleCheckbox = useCheckboxHandle(setOn);

	const toggleOn = React.useCallback(() => {
		setOn(true);
	}, [setOn]);

	const toggleOff = React.useCallback(() => {
		setOn(false);
	}, [setOn]);

	const toggle = React.useCallback(() => {
		setOn((prevValue) => !prevValue);
	}, [setOn]);

	return { isOn, setOn, toggle, toggleOn, toggleOff, handleCheckbox };
}

type APIProps = {
	props?: Partial<FetchAPIProps>;
	autoLoad?: boolean;
};

export function useAPI({ autoLoad, props }: APIProps = {}) {
	const dispatch = useDispatch();

	const [state, changeState] = React.useState<any>({
		loading: false,
		error: null,
	});

	const setState = React.useCallback(
		(newState) => changeState((prevState: any) => ({ ...prevState, ...newState })),
		[],
	);

	const showConfirmation = useConfirmation();

	const fetchAPI = React.useCallback(
		({ confirmation, ...rest }: Partial<FetchAPIProps>) => {
			const doFetch = () => fetch({ ...rest, setState, dispatch });

			if (typeof confirmation === 'object') showConfirmation(doFetch, confirmation);
			else if (typeof confirmation === 'function') {
				const statedConfirm = confirmation(rest);
				if (statedConfirm)
					showConfirmation(doFetch, typeof statedConfirm === 'boolean' ? {} : statedConfirm);
				else doFetch();
			} else if (confirmation) showConfirmation(doFetch);
			else doFetch();
		},
		[setState, dispatch, showConfirmation],
	);

	const [initialProps] = React.useState(props);

	const initialFetch = React.useCallback(
		(additionalProps = {}) => fetchAPI({ ...initialProps, ...additionalProps, queryId: 'auto' }),
		[fetchAPI, initialProps],
	);

	React.useEffect(() => {
		if (autoLoad) initialFetch();
	}, [autoLoad, initialFetch]);

	return {
		state,
		fetchAPI,
		initialFetch,
	};
}

export function useNavigation() {
	const dispatch = useDispatch();
	const history = useHistory();
	const goToPortal = React.useCallback(() => {
		dispatch(checkAuth() as any);
		history.replace('/portal');
	}, [dispatch, history]);

	return { goToPortal, goBack };
}

export function useGravatar(email?: string) {
	return React.useMemo(() => getAvatarUrl(email), [email]);
}

export function useHistoryQS(key: string) {
	const location = useLocation();

	return React.useMemo(() => parse(location.search)[key], [key, location.search]);
}

export type MenuHandlers = {
	openMenu: (e) => void;
	menuAnchor: HTMLElement;
	closeMenu: () => void;
};

export function useMenuHandlers(): MenuHandlers {
	const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
	const openMenu = React.useCallback((e) => setMenuAnchor(e.currentTarget), [setMenuAnchor]);
	const closeMenu = React.useCallback(() => setMenuAnchor(null), [setMenuAnchor]);

	return { menuAnchor, openMenu, closeMenu };
}

export function useWindowCloseConfirmation(isEnabled?: boolean) {
	const history = useHistory();

	React.useEffect(() => {
		const onUnload = (e: { preventDefault: () => void; returnValue: string }) => {
			if (!isEnabled) return;
			e.preventDefault();
			e.returnValue = '';
		};

		window.addEventListener('beforeunload', onUnload);

		return () => {
			window.removeEventListener('beforeunload', onUnload);
		};
	}, [isEnabled, history]);
}

export function useHistoryPush(url: string) {
	const history = useHistory();
	return React.useCallback(() => history.push(url), [history, url]);
}

export function useTitle(title: string) {
	React.useEffect(() => {
		document.title = title;
	}, [title]);
}
