import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { stringify } from 'query-string';
import { css } from '@emotion/css/macro';
import { useHistory } from 'react-router';
import { Help, Lock, Mic, SentimentVeryDissatisfied } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Heading } from '../core/components';
import { PanelProps } from './rocItems/TableRow';
import { error } from '../ui/Core/stylesheets/colors';
import { useAllowedMethods, useReportAccess } from '../auth/hooks';
import { getItemEmailHighlightQS } from './helpers';
import { hasFlag } from '../core/helpers';
import { InterviewsButtonMenu } from './InterviewsButtonMenu';
import { useToggle } from '../core/hooks';
import { useAuthContext } from '../auth/context';
import MaterialFlyOut from '../flyout/components/MaterialFlyOut';
import WorkflowPeerReviewQuickApprove from '../workflow/components/WorkflowPeerReviewQuickApprove';
import { useItemContext } from './context/ItemContext';
import { useReportContext } from './context/ReportContext';
import CellStatus from './CellStatus';
import { RocItem } from './types';
import ItemAttendeesList from '../interviews/components/ItemAttendeesList';
import ItemReasons from './ItemReasons';
import { NonComplianceReason } from '../../graphql/typescript';
import { RocDataType } from './enums';
import useNotApplicableControl from './useNotApplicableControl';
import RocWarnings from './RocWarnings';
import WorkflowButton from '../workflow/components/WorkflowButton';
import InterviewImport from '../../features/Interviews/InterviewImport';
import SimplifiedButton from './simplifiedControls/SimplifiedButton';
import SimplifiedButtonGroup from './simplifiedControls/SimplifiedButtonGroup';
import HintsFlyOut from '../hints/components/HintsFlyOut';
import { SCROLL_INTO_VIEW_CONFIG } from '../core/constants/scrollIntoViewConfig';

const rootClass = css`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 8px;
`;

const responsibilityMatchClass = css`
	margin-left: 0.75rem;
	font-size: 14px;
	font-weight: bold;
	color: ${error};
`;

const guidanceClass = css`
	white-space: pre-wrap;
	max-width: 400px;
`;

type UserControlsProps = PanelProps & Partial<RocItem>;

function UserControls({
	isHintsEnabled,
	responsibilityArea,
	notApplicable,
	nonCompliant,
	notApplicableReasons,
	nonComplianceReasons,
	availableChannelOptions,
	guidance,
	applicabilityNotes,
	parentProgressItemId,
}: UserControlsProps) {
	const history = useHistory();
	const ref = React.useRef<HTMLDivElement>(null);
	const { isGlobalAdmin, isAQSA, isAdmin, isQSA } = useReportAccess();
	const { itemId, isItemApproved, lists, itemProperties, rocWarnings, isReadOnly, rocDataType } =
		useItemContext();
	const { projectId, refresh } = useReportContext();
	const { clientResponsibility } = useAuthContext();
	const isResponsibilityMatch = React.useMemo(
		() =>
			!!responsibilityArea &&
			!!clientResponsibility &&
			hasFlag(responsibilityArea, clientResponsibility),
		[clientResponsibility, responsibilityArea],
	);

	const { isOn: isGuidanceOn, toggleOn: guidanceOn, toggleOff: guidanceOff } = useToggle();

	const goToCreateInterview = React.useCallback(() => {
		const qs = stringify({ id: itemId, return: 1 });
		history.push(`/portal/interviews/project/${projectId}/new?${qs}`);
	}, [itemId, projectId, history]);

	const {
		'Interview/CreateInterview': canRequestInterview,
		'RocItemControllerNew/SetApplicable': canSetApplicable,
		'RocItemControllerNew/SetNonComplianceReason': canSetNonComplianceReason,
	} = useAllowedMethods();

	//scrollTo and open discussion from email
	const [scrolled, setScrolled] = React.useState(false);

	React.useEffect(() => {
		const high = getItemEmailHighlightQS();
		const itemHighlighted = high.id === itemId;
		if (ref.current && !scrolled && itemHighlighted) {
			ref.current.scrollIntoView(SCROLL_INTO_VIEW_CONFIG);
			setScrolled(true);
		}
	}, [scrolled, setScrolled, itemId]);

	//Non-compliance handling
	const [complianceReasonsState, setComplianceReasons] = React.useState<
		NonComplianceReason[] | undefined
	>(nonComplianceReasons);
	React.useEffect(() => {
		if (!nonCompliant) setComplianceReasons(undefined);
	}, [nonCompliant]);
	const clickNonCompliant = React.useCallback(
		() => setComplianceReasons([{}]),
		[setComplianceReasons],
	);

	//Not applicable handling
	const { notApplicableReasonsComponent, clickNotApplicable } = useNotApplicableControl({
		notApplicable,
		notApplicableReasons,
	});

	//Other
	const canSeeInterviewControls =
		(rocDataType === RocDataType.InterviewAttendee || rocDataType === RocDataType.InterviewEvent) &&
		canRequestInterview &&
		!isItemApproved;

	if (!itemId) return null;

	return (
		<>
			<div id={itemId} ref={ref} className={rootClass}>
				<Box display="flex" alignItems="flex-start">
					{itemProperties.isProjectItem && !parentProgressItemId && <CellStatus />}
					{rocWarnings > 0 && (
						<RocWarnings
							projectId={projectId}
							refresh={refresh}
							canClearWarnings={isAdmin || isQSA}
						/>
					)}
				</Box>
				<SimplifiedButtonGroup data-testid={`UserControls_${itemId}`}>
					{isHintsEnabled && (
						<HintsFlyOut
							list={lists.hints}
							isAQSA={isAQSA}
							isGlobalAdmin={isGlobalAdmin}
							itemId={itemId}
						/>
					)}
					{canSeeInterviewControls && (
						<SimplifiedButton startIcon={<Mic />} tabIndex={-1} onClick={goToCreateInterview}>
							Request Interview
						</SimplifiedButton>
					)}
					{canSeeInterviewControls && lists.interviews.length > 0 && (
						<InterviewsButtonMenu
							modelId={projectId}
							itemId={itemId}
							interviews={lists.interviews}
						/>
					)}
					{canSeeInterviewControls && <InterviewImport projectId={projectId} itemId={itemId} />}
					{itemProperties.isProjectInput && !parentProgressItemId && (
						<WorkflowButton
							channels={availableChannelOptions}
							itemId={itemId}
							list={lists.workflows}
							itemResponsibility={responsibilityArea}
						/>
					)}
					{guidance && (
						<SimplifiedButton startIcon={<Help />} onClick={guidanceOn}>
							Guidance{!!applicabilityNotes && ' & Applicability Notes'}
						</SimplifiedButton>
					)}
					{canSetApplicable &&
						itemProperties.isProjectInput &&
						!itemProperties.isSummary &&
						!isItemApproved &&
						!notApplicable &&
						(!notApplicableReasons || notApplicableReasons.length < 1) &&
						!isReadOnly && (
							<Tooltip title="Mark as not applicable and lock">
								<SimplifiedButton startIcon={<Lock />} tabIndex={-1} onClick={clickNotApplicable}>
									Mark N/A
								</SimplifiedButton>
							</Tooltip>
						)}
					{canSetNonComplianceReason &&
						itemProperties.isProjectInput &&
						nonCompliant &&
						(!nonComplianceReasons || nonComplianceReasons.length < 1) &&
						!isItemApproved &&
						!isReadOnly && (
							<SimplifiedButton
								startIcon={<SentimentVeryDissatisfied htmlColor={error} />}
								tabIndex={-1}
								onClick={clickNonCompliant}
								color="secondary"
							>
								Set non-compliance reason
							</SimplifiedButton>
						)}
					{isResponsibilityMatch && (
						<div className={responsibilityMatchClass}>Responsibility Match</div>
					)}
				</SimplifiedButtonGroup>
				{itemProperties.isAttendeesList && <ItemAttendeesList />}
			</div>
			<WorkflowPeerReviewQuickApprove />
			{nonCompliant && !!complianceReasonsState && complianceReasonsState.length > 0 && (
				<ItemReasons
					methodName="SetNonComplianceReason"
					reasons={complianceReasonsState}
					setReasons={setComplianceReasons}
					canEdit={canSetNonComplianceReason}
					nonCompliantVariant
				/>
			)}
			{notApplicableReasonsComponent}
			<MaterialFlyOut
				className={guidanceClass}
				open={isGuidanceOn}
				onClose={guidanceOff}
				title="Information"
			>
				<Heading variant="h6" gutterBottom>
					Guidance
				</Heading>
				<p>{guidance}</p>
				{applicabilityNotes && (
					<>
						<Heading variant="h6" gutterBottom>
							Applicability Notes
						</Heading>
						{applicabilityNotes}
					</>
				)}
			</MaterialFlyOut>
		</>
	);
}

export default function UserControlsWrapper({
	isHintsEnabled,
	isGuidanceEnabled,
	ignoreProgress,
	...rest
}: UserControlsProps) {
	const { itemProperties } = useItemContext();

	if (ignoreProgress || (!isHintsEnabled && !isGuidanceEnabled && !itemProperties.isProjectInput))
		return null;

	return (
		<UserControls isHintsEnabled={isHintsEnabled} isGuidanceEnabled={isGuidanceEnabled} {...rest} />
	);
}
