import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../modules/auth/AuthReducers';
import popupReducer from '../modules/popup/reducers';
import loadingReducer from '../modules/loading/reducers';

const reducers = {
	authReducer,
	popupReducer,
	loadingReducer,
};

function createRootReducer() {
	return combineReducers({
		...reducers,
	});
}

const middleware = [thunk];

export default function configureStore(initialState?: any) {
	return createStore(createRootReducer(), initialState, applyMiddleware(...middleware));
}
