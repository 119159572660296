import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { HubConnectionState } from '@microsoft/signalr';
import withAuthInfo from '../../modules/auth/containers/withAuthInfo';
import { Portal } from './lazy';
import { LAST_PATH_KEY } from '../../modules/auth/constants';
import { hub } from '../../modules/hubs/constants';
import { UserInfoSuccessResponse } from '../../graphql/typescript';
import { isAuthenticated } from '../../modules/auth/helpers';
import { getQS } from '../../modules/core/helpers';
import { persistMemoryCache, readPersistency } from '../../modules/apollo/persistency';
import { inMemoryCache } from '../../modules/apollo/cache';
import { AuthContextWrapper } from '../../modules/auth/context';
import { useHubState } from '../../modules/hubs/hooks';
import { HubActionType } from '../../modules/hubs/types';
import LicenseAlerts from '../../modules/license/LicenseAlerts';

setInterval(() => {
	if (isAuthenticated()) {
		if (window.location.pathname === '/login') {
			const returnPath = getQS(LAST_PATH_KEY);
			window.location.href = returnPath ? decodeURIComponent(String(returnPath)) : '/portal';
		}
	}
}, 10000);

setInterval(() => persistMemoryCache(), 5000);

type AuthOnlyPortalProps = {
	userInfo: UserInfoSuccessResponse;
	loading?: boolean;
};

function AuthOnlyPortal({ userInfo, loading, ...rest }: AuthOnlyPortalProps) {
	const client = useApolloClient();
	const history = useHistory();
	const [, dispatchHub] = useHubState();
	const { id } = userInfo || {};

	React.useEffect(() => {
		if (id) {
			const storedCache = readPersistency();
			if (storedCache) inMemoryCache.restore(storedCache);
			if (hub.state === HubConnectionState.Disconnected) hub.start();
		} else {
			client.clearStore();
			const lastPathRegex = window.location.href.match(/(\/\/[^/]*)(.*)/);
			const lastPath = lastPathRegex ? lastPathRegex[2] : '/portal';
			history.replace({
				pathname: '/login',
				search: lastPathRegex ? `?${LAST_PATH_KEY}=${encodeURIComponent(lastPath)}` : '',
			});
			if (hub.state === HubConnectionState.Connected) {
				dispatchHub({ type: HubActionType.CLEAR_HUB });
				hub.stop();
			}
		}
	}, [history, client, id, dispatchHub]);

	if (loading) return null;

	return (
		<AuthContextWrapper userInfo={userInfo}>
			<Portal {...rest} />
			<LicenseAlerts />
		</AuthContextWrapper>
	);
}

export default withAuthInfo(AuthOnlyPortal);
