import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { getTokenQS } from '../core/helpers';
import { useAPI } from '../core/hooks';
import { AUTH_TOKEN_KEY, LAST_PATH_KEY } from '../auth/constants';
import { Gates, Heading } from '../core/components';
import { checkAuth, signOut } from '../auth/AuthReducers';
import { idsToProjectPath } from '../project/helpers';

export function TempLogin() {
	const token = getTokenQS();
	const history = useHistory();
	const dispatch = useDispatch();

	useAPI({
		props: {
			query: 'Workflow/TempLogin',
			qs: { token },
			onSuccess: (response) => {
				const { projectId, chapterId, itemId, responseToken, logout, userName, contactName } =
					response.data;

				if (responseToken) localStorage.setItem(AUTH_TOKEN_KEY, responseToken);
				if (logout) dispatch(signOut() as any);

				dispatch(checkAuth() as any);

				if (userName) {
					const encodedItemUrl = encodeURIComponent(idsToProjectPath(projectId, chapterId, itemId));
					const encodedUserName = encodeURIComponent(userName);
					history.push(`/login?userName=${encodedUserName}&${LAST_PATH_KEY}=${encodedItemUrl}`);
				} else {
					let link = `/respond/${projectId}/${itemId}?token=${encodeURIComponent(
						String(token),
					)}&id=${itemId}`;
					if (contactName) {
						const encodedContactName = encodeURIComponent(contactName);
						link += `&name=${encodedContactName}`;
					}
					history.push(link);
				}
			},
		},
		autoLoad: !!token,
	});

	if (!token)
		return (
			<Gates>
				<Heading variant="h6">Token not found. Access denied.</Heading>
			</Gates>
		);

	return null;
}
